import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchMonitorLocations } from 'app/business-logic/services/monitor-service/fetchMonitorLocations';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { useAppSelector } from 'app/redux/store';
import { DateTime } from 'luxon';
import { useMonitors } from 'app/hooks/useMonitors';
import Guid from 'core/types/Guid';

const ONE_HOUR_MILLIS = 1000 * 60 * 60;

/**
 * Will return locations for all monitors based on the current map time if process IDs or a timestamp aren't provided
 */
export const useMonitorLocations = (params?: { processIds?: Guid[]; dateTimeIso?: string }) => {
  const { processIds, dateTimeIso } = params ?? {};
  const { data: monitors } = useMonitors();
  const mapTimeIso = useMapTimeIso();
  const monitorIds = useMemo(() => monitors.map(m => m.id), [monitors]);
  const { data } = useQuery({
    queryFn: () =>
      fetchMonitorLocations({
        processIds: processIds ?? monitorIds,
      }),
    queryKey: [QUERY_KEY.monitorLocations, processIds, monitorIds],
    enabled: !!processIds?.length || !!monitorIds.length,
    staleTime: ONE_HOUR_MILLIS,
  });

  return useMemo(() => {
    return data?.flatMap(({ id, locationHistory }) => {
      const location = locationHistory.toSorted(sortByNewestFirst).find(lh => {
        return (dateTimeIso ?? mapTimeIso) >= lh.activeFromTime;
      });
      if (!location) return [];
      return {
        id,
        location,
      };
    });
  }, [data, dateTimeIso, mapTimeIso]);
};

function useMapTimeIso() {
  const { mapTimeMillis } = useAppSelector(state => state.map);
  return useMemo(() => {
    const iso = DateTime.fromMillis(mapTimeMillis).toUTC().toISO();
    if (!iso) throw new Error('Failed to get map time for monitor markers');
    return iso;
  }, [mapTimeMillis]);
}

function sortByNewestFirst(
  { activeFromTime: activeFromTimeA }: { activeFromTime: string },
  { activeFromTime: activeFromTimeB }: { activeFromTime: string }
) {
  if (activeFromTimeA > activeFromTimeB) return -1;
  if (activeFromTimeB > activeFromTimeA) return 1;
  return 0;
}
