import Guid from 'core/types/Guid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from '../actions';

export const DATE_RANGES = ['LAST_HOUR', 'LAST_6_HOURS', 'LAST_24_HOURS', 'LAST_7_DAYS'] as const;
export type DateRange = (typeof DATE_RANGES)[number];

export const INITIAL_STATE = {
  selectedMonitorIds: [] as Guid[],
  selectedSourceAreaIds: [] as Guid[],
  dateRange: 'LAST_24_HOURS' as DateRange,
  selectedProcessVariableIds: [] as Guid[],
  selectedLowLatencyVariableIds: [] as string[],
  selectedCustomProcessVariableIds: [] as Guid[],
};

export const slice = createSlice({
  name: 'analyse',
  initialState: INITIAL_STATE,
  reducers: {
    selectSourceArea: (state, { payload }: PayloadAction<Guid>) => {
      if (!state.selectedSourceAreaIds.includes(payload)) {
        state.selectedSourceAreaIds.push(payload);
      }
    },

    deselectSourceArea: (state, { payload }: PayloadAction<Guid>) => {
      state.selectedSourceAreaIds = state.selectedSourceAreaIds.filter(id => id !== payload);
    },

    selectMonitor: (state, { payload }: PayloadAction<Guid>) => {
      if (!state.selectedMonitorIds.includes(payload)) {
        state.selectedMonitorIds.push(payload);
      }
    },

    deselectMonitor: (state, { payload }: PayloadAction<Guid>) => {
      state.selectedMonitorIds = state.selectedMonitorIds.filter(id => id !== payload);
    },

    deselectAllSourceAreas: state => {
      state.selectedSourceAreaIds = [];
    },

    deselectAllProcessVariables: state => {
      state.selectedProcessVariableIds = [];
    },

    deselectAllLowLatencyVariables: state => {
      state.selectedLowLatencyVariableIds = [];
    },

    deselectAllMonitors: state => {
      state.selectedMonitorIds = [];
    },

    clearAllMonitoringSelections: state => {
      state.selectedMonitorIds = [];
      state.selectedProcessVariableIds = [];
      state.selectedSourceAreaIds = [];
      state.selectedLowLatencyVariableIds = [];
      state.selectedCustomProcessVariableIds = [];
    },

    setDateRange: (state, { payload }: PayloadAction<DateRange>) => {
      state.dateRange = payload;
    },

    selectProcessVariable: (state, { payload }: PayloadAction<Guid>) => {
      if (!state.selectedProcessVariableIds.includes(payload)) {
        state.selectedProcessVariableIds.push(payload);
      }
    },

    deselectProcessVariables(state, { payload }: PayloadAction<Guid[]>) {
      state.selectedProcessVariableIds = state.selectedProcessVariableIds.filter(id => !payload.includes(id));
    },

    selectLowLatencyVariable: (state, { payload }: PayloadAction<string>) => {
      if (!state.selectedLowLatencyVariableIds.includes(payload)) {
        state.selectedLowLatencyVariableIds.push(payload);
      }
    },

    deselectLowLatencyVariables: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedLowLatencyVariableIds = state.selectedLowLatencyVariableIds.filter(id => !payload.includes(id));
    },

    selectCustomProcessVariable: (state, { payload }: PayloadAction<Guid>) => {
      if (!state.selectedCustomProcessVariableIds.includes(payload)) {
        state.selectedCustomProcessVariableIds.push(payload);
      }
    },

    deselectCustomProcessVariable: (state, { payload }: PayloadAction<Guid>) => {
      state.selectedCustomProcessVariableIds = state.selectedCustomProcessVariableIds.filter(id => id !== payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
