import { useEventHistoryData } from 'app/views/alerts/pages/event-history/use-event-history-data';
import HttpResponse from 'core/net/HttpResponse';
import { httpClient } from 'core/net/http';
import * as v from 'valibot';
import { LogManager } from 'core/logging/LogManager';

const logger = LogManager.getLogger('getDetailedNoiseEvents');

const FileType = v.union([v.literal('wav'), v.literal('mp3'), v.literal('opus')]);

const DetailedNoiseEventSchema = v.object({
  noiseEventId: v.number(),
  locationId: v.number(),
  deviceId: v.number(),
  leq: v.nullish(v.number()),
  leqMax: v.nullish(v.number()),
  lfMax: v.nullish(v.number()),
  lsMax: v.nullish(v.number()),
  liMax: v.nullish(v.number()),
  sel: v.nullish(v.number()),
  windSpeed: v.nullish(v.number()),
  weighting: v.string(),
  startTime: v.pipe(v.string(), v.isoTimestamp()),
  endTime: v.pipe(v.string(), v.isoTimestamp()),
  audioResourcePath: v.nullish(v.string()),
  fileType: v.nullish(FileType),
});

const DetailedNoiseEventsResponseSchema = v.object({
  noiseEvents: v.array(DetailedNoiseEventSchema),
  pageCount: v.number(),
});

type EventHistoryNoiseEvent = Pick<
  NonNullable<ReturnType<typeof useEventHistoryData>['data']>[number],
  'noiseEventId' | 'locationId' | 'leq' | 'lfMax' | 'sel' | 'windSpeed' | 'startTime' | 'endTime' | 'weighting'
>;

type Options = {
  noiseEventIds?: number[];
  deviceIds?: number[];
  locationIds?: number[];
  from?: string;
  to?: string;
  page?: number;
  pageSize?: number;
};

type RequiredNoiseEventIds = Required<Pick<Options, 'noiseEventIds'>>;
type RequiredFromTo = Required<Pick<Options, 'from' | 'to'>>;

type DetailedNoiseEventsResponse = v.InferOutput<typeof DetailedNoiseEventsResponseSchema>;

export async function getDetailedNoiseEvents(
  options: RequiredNoiseEventIds & Options
): Promise<EventHistoryNoiseEvent[]>;

export async function getDetailedNoiseEvents(options: RequiredFromTo & Options): Promise<EventHistoryNoiseEvent[]>;

export async function getDetailedNoiseEvents(options: Options): Promise<EventHistoryNoiseEvent[]> {
  const { noiseEventIds, from, to } = options;
  if ((!noiseEventIds || noiseEventIds.length === 0) && (!from || !to)) {
    throw new Error('Either "noiseEventIds" must be provided, or both "from" and "to" must be provided.');
  }
  const { data, isSuccess }: HttpResponse<DetailedNoiseEventsResponse> = await httpClient.useArenaInternalApi
    .asAuthorised()
    .get('noise-events-detailed', {
      ...options,
    });
  const result = v.safeParse(DetailedNoiseEventsResponseSchema, data);

  if (!isSuccess || !data || !result.success) {
    logger.error('\n', result.issues?.map(v => JSON.stringify(v, null, 2)).join('\n'));
    throw new Error(
      `Something went wrong when trying to get detailed noise events using the following options:\n${JSON.stringify(
        options,
        null,
        2
      )}`
    );
  }

  return result.output.noiseEvents.map(
    ({ leq, lfMax, sel, windSpeed, endTime, locationId, noiseEventId, startTime, weighting }) => {
      const event: EventHistoryNoiseEvent = {
        leq,
        lfMax,
        sel,
        windSpeed,
        noiseEventId,
        locationId,
        startTime,
        endTime,
        weighting,
      };
      return event;
    }
  );
}
