import { useI18n } from 'core/hooks/useI18n';
import type { Options } from 'highcharts/highstock';
import { HighchartsReactRefObject as ChartRef, HighchartsReact } from 'highcharts-react-official';
import HighchartsStock from 'highcharts/highstock';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import offline from 'highcharts/modules/offline-exporting';
import { useEffect, useMemo, useRef, HTMLAttributes } from 'react';
import { ChartWrapper, StyledChart } from './Chart.styles';

type ChartProps = {
  options: Options;
  isLoading?: boolean;
  containerProps?: HTMLAttributes<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

addNoDataModule(HighchartsStock);
offline(HighchartsStock);

// override hasData method as it does not handle windbarb series correctly
HighchartsStock.Chart.prototype.hasData = function () {
  return this.series.some(series => {
    return series.hasData() || !!series.points.length;
  });
};

export const Chart = ({ options, isLoading, containerProps, ...rest }: ChartProps) => {
  const chartRef = useRef<ChartRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const translations = useTranslations();
  const chartOptions: Options = useMemo(
    () => ({
      ...options,
      lang: {
        ...translations,
        ...options.lang,
      },
    }),
    [options, translations]
  );

  useLoadingState({ chartRef: chartRef.current, isLoading });
  useResizeObserver(containerRef, () => {
    chartRef.current?.chart?.reflow();
  });

  return (
    <ChartWrapper ref={containerRef}>
      <StyledChart {...rest}>
        <HighchartsReact
          highcharts={HighchartsStock}
          ref={chartRef}
          options={chartOptions}
          containerProps={containerProps}
        />
      </StyledChart>
    </ChartWrapper>
  );
};

function useLoadingState({ chartRef, isLoading }: { chartRef: ChartRef | null; isLoading?: boolean }) {
  useEffect(() => {
    if (!chartRef) return;
    if (isLoading) {
      chartRef.chart?.showLoading();
    } else {
      chartRef.chart?.hideLoading();
    }
  }, [isLoading, chartRef]);
}

function useTranslations() {
  const { l10n } = useI18n('app/components', 'highcharts');
  const noData = l10n('noData').split(' ');
  // Add line break to prevent text overflow on smaller charts
  noData.splice(Math.floor(noData.length / 2), 0, '<br />');

  return useMemo(
    () => ({
      loading: l10n('loading'),
      noData: noData.join(' '),
      resetZoom: l10n('resetZoom'),
      resetZoomTitle: l10n('resetZoomTitle'),
    }),
    [l10n, noData]
  );
}

const useResizeObserver = (ref: React.RefObject<HTMLDivElement>, callback: () => void) => {
  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      callback();
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [ref, callback]);
};
