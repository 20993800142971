import { useAppSelector } from 'app/redux/store';
import { useThemeContext } from 'app/theme/useThemeContext';
import Color from 'color';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useUtcDateTimeEnd } from '../useUtcDateTimeEnd';
import { useUtcDateTimeStart } from '../useUtcDateTimeStart';

const MOBILE_CHART_HEIGHT = 150;

export const useDefaultOptions = () => {
  const theme = useThemeContext();
  const dateTimeEnd = useUtcDateTimeEnd();
  const dateTimeStart = useUtcDateTimeStart(dateTimeEnd);
  const { ianaTimezone } = useAppSelector(state => state.profile.facility);
  const xMin = dateTimeStart ? DateTime.fromISO(dateTimeStart).toMillis() : undefined;
  const xMax = dateTimeEnd ? DateTime.fromISO(dateTimeEnd).toMillis() : undefined;
  const timezoneOffset = dateTimeEnd ? -DateTime.fromISO(dateTimeEnd).setZone(ianaTimezone).offset : undefined;
  const options: Highcharts.Options = useMemo(
    () => ({
      boost: {
        enabled: true,
      },
      chart: {
        backgroundColor: theme.colors.mono.ui02,
        zoomType: 'x',
        style: {
          fontFamily: theme.font.default.family,
        },
      },
      credits: { enabled: false },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      loading: {
        style: {
          backgroundColor: Color(theme.colors.mono.ui04).fade(0.15).toString(),
          opacity: 1,
        },
        labelStyle: {
          color: theme.colors.mono.text01,
          fontSize: '1.8rem',
        },
      },
      noData: {
        style: {
          color: theme.colors.mono.text01,
          fontSize: '1.8rem',
          opacity: 1,
          textAlign: 'center',
        },
        position: {
          align: 'center',
        },
        useHTML: true,
      },
      responsive: {
        rules: [
          {
            chartOptions: {
              chart: {
                height: MOBILE_CHART_HEIGHT,
              },
              noData: {
                style: {
                  fontSize: '1.2rem',
                },
              },
            },
            condition: {
              maxWidth: theme.breakpoints.values.sm - 1,
            },
          },
        ],
      },
      time: {
        timezoneOffset,
      },
      title: {
        text: undefined,
      },
      xAxis: {
        crosshair: true,
        endOnTick: true,
        labels: {
          style: {
            color: theme.colors.mono.ui06,
            fontSize: '1.4rem',
          },
        },
        max: xMax,
        min: xMin,
        tickColor: theme.colors.mono.ui06,
        type: 'datetime',
      },
    }),
    [theme, timezoneOffset, xMax, xMin]
  );
  return options;
};
