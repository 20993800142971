import { AppSettings } from 'core/AppSettings';
import { LogManager } from 'core/logging/LogManager';

const logger = LogManager.getLogger('registerServiceWorker');

const SERVICE_WORKER_URL = AppSettings.isDevelopment
  ? '/dev-sw.js?dev-sw' // Development service worker, the name is generated by vitePwaPlugin
  : '/azureMapTilesCache.js'; // Production service worker

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(SERVICE_WORKER_URL, {
        type: 'module',
      })
      .then(registration => {
        logger.debug('[Service Worker] Registered successfully', registration);
      })
      .catch(error => {
        logger.error('[Service Worker] Registration failed', error);
      });
  }
};
