import { Classes } from '@blueprintjs/core';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { CellContext, Header, PaginationState, Updater } from '@tanstack/table-core';
import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { NoiseEvent } from 'app/business-logic/services/noise-service';
import { Throbber } from 'app/components/throbber';
import { useMediaQuery } from 'app/helpers/useMediaQuery';
import { useHasAccess } from 'app/hooks/use-has-access';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { deselectAlertEvent, selectAlertEvent } from 'app/redux/ui/actions';
import { useThemeContext } from 'app/theme/useThemeContext';
import { DATA_TRACKING_KEY } from 'app/views/components/heap-analytics';
import { useAlertResponseCategories } from 'app/views/configuration/pages/alert/alert-responses/alert-response-form/useAlertResponseCategories';
import { useNoiseMonitors } from 'app/views/monitoring/useNoiseMonitors';
import { useI18n } from 'core/hooks/useI18n';
import { DateTime } from 'luxon';
import { ComponentProps, Fragment, RefObject, Suspense, useCallback, useMemo } from 'react';
import { useAlertById } from '../../hooks';
import { EventClassification } from '../event-classification';
import { EventClassificationComments } from '../event-classification-comments';
import { EventClassificationIcons } from '../event-classification-icons';
import { EventNoisePlayback } from '../event-noise-playback';
import { EventElementsMap } from '../events-chart/use-select-alert-event';
import {
  ActionCell,
  EventsTable,
  EventsTableBody,
  EventsTableCell,
  EventsTableHead,
  EventsTableHeading,
  EventsTableHeadingInner,
  EventsTableHeadingText,
  EventsTableRow,
  EventsTableWrapper,
  ExpandedCell,
  ExpandRowButton,
  ExpandRowButtonIcon,
  Loading,
  LoadingEventsThrobber,
  NoEvents,
  NoMoreEvents,
} from './EventsListing.styles';
import { TablePagination } from 'app/components/table/table-pagination';
import { useSearchParams } from 'app/hooks/use-search-params';
import { PAGE_SIZE, PAGINATION_PAGE_LIMIT, SEARCH_PARAM_PAGE } from './EventsListing.constants';
import { EventsListingNoResults } from './EventListingNoResults';

type EventsListingProps = ComponentProps<typeof EventsTableWrapper> & {
  data: NoiseEvent[] | undefined;
  isFetching: boolean;
  isLoading: boolean;
  eventElementsMap: RefObject<EventElementsMap>;
  hasNoMoreResults?: boolean;
  selectedAlertId?: string;
  total?: number;
};

const ACTIONS_CELL_ID = 'actions';
const FALLBACK_DATA: NoiseEvent[] = [];
const NO_DATA = '--';

export const EventsListing = ({
  data,
  isFetching,
  isLoading,
  eventElementsMap,
  hasNoMoreResults,
  selectedAlertId,
  total,
  ...rest
}: EventsListingProps) => {
  const { l10n } = useI18n('app/views/alerts', 'eventsListing');
  const columns = useEventHistoryTableColumns();
  const {
    searchParams: {
      page: [page],
    },
  } = useSearchParams([SEARCH_PARAM_PAGE]);
  const pageIndex = page ? Number(page) - 1 : 0;
  const onPaginationChange = useHandlePaginationChange();

  const table = useReactTable<NoiseEvent>({
    data: data ?? FALLBACK_DATA,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: { pageSize: PAGE_SIZE, pageIndex },
    },
    state: { pagination: { pageSize: PAGE_SIZE, pageIndex } },
    pageCount: total ? Math.ceil(total / PAGE_SIZE) : 0,
    manualPagination: true,
    onPaginationChange,
  });

  const { data: alert } = useAlertById(selectedAlertId);
  const { data: alertResponseCategories } = useAlertResponseCategories();
  const category = alertResponseCategories?.find(c => c.id === alert?.alertTypeId);
  const { selectedAlertEvent } = useAppSelector(state => state.ui);
  const handleExpansion = useHandleRowExpansion();
  const headerGroups = useMemo(() => table.getHeaderGroups(), [table]);
  const noMoreResults = hasNoMoreResults && !isFetching;
  const noResults = !data?.length && !isFetching;
  const theme = useThemeContext();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  if (noResults && category?.slug === 'noise') return <NoEvents>{l10n('results.noEvents')}</NoEvents>;
  if (noResults) return <EventsListingNoResults />;

  return (
    <>
      <EventsTableWrapper {...rest}>
        <EventsTable $isFetching={isFetching}>
          <EventsTableHead>
            {headerGroups.map(headerGroup => (
              <EventsTableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  /**
                   * Disabled sorting-related functionality for the time being as it would likely result
                   * in more work, e.g. persisting state locally and returning sorted data from the server
                   */
                  // const sortDirection = header.column.getIsSorted();
                  // const isDescending = header.column.getIsSorted() === 'desc';
                  const isActionsCell = header.column.id === ACTIONS_CELL_ID;
                  return (
                    <EventsTableHeading key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                      {header.isPlaceholder || isActionsCell ? null : (
                        <EventsTableHeadingInner
                          as="span"
                          // onClick={header.column.getToggleSortingHandler()}
                          // aria-label={l10n('action.sort')}
                        >
                          <EventsTableHeadingText>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </EventsTableHeadingText>
                          {/* {sortDirection && <SortIcon data-testid="sorting-icon" $isDescending={isDescending} />} */}
                        </EventsTableHeadingInner>
                      )}
                    </EventsTableHeading>
                  );
                })}
              </EventsTableRow>
            ))}
          </EventsTableHead>
          <EventsTableBody>
            {!isFetching &&
              table.getRowModel().rows.map(row => {
                const { id } = row.original;
                const isExpanded = selectedAlertEvent === row.original.id;
                const cells = row.getVisibleCells();
                return (
                  <Fragment key={row.original.id}>
                    <EventsTableRow
                      $isSelectable={true}
                      $isExpanded={isExpanded}
                      ref={(ref: HTMLTableRowElement) => eventElementsMap.current?.set(id, ref)}
                      data-testid="events-listing-row"
                      data-tracking={DATA_TRACKING_KEY['event-history-event-row']}
                    >
                      {cells.map(cell => {
                        const isActionsCell = cell.column.id === ACTIONS_CELL_ID;
                        if (isActionsCell && isDesktop) {
                          return (
                            <ActionCell
                              style={{
                                maxWidth: cell.column.getSize(),
                                width: cell.column.getSize(),
                              }}
                              key={cell.id}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </ActionCell>
                          );
                        }
                        return (
                          <EventsTableCell
                            onClick={handleExpansion(id)}
                            style={{
                              maxWidth: cell.column.getSize(),
                              width: cell.column.getSize(),
                            }}
                            key={cell.id}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </EventsTableCell>
                        );
                      })}
                    </EventsTableRow>
                    {isExpanded && (
                      <EventsTableRow aria-describedby={`expand-row-button-${id}`}>
                        <ExpandedCell colSpan={cells.length}>
                          <Suspense
                            fallback={
                              <Loading>
                                <Throbber>{l10n('activity.loadingEvent')}</Throbber>
                              </Loading>
                            }
                          >
                            <EventNoisePlayback noiseEventId={id} startTime={row.original.startTime ?? ''} />
                            <EventClassification event={row.original} handleExpansion={handleExpansion(id)} />
                          </Suspense>
                        </ExpandedCell>
                      </EventsTableRow>
                    )}
                  </Fragment>
                );
              })}
            {isFetching && <LoadingRows headers={headerGroups[0]?.headers} />}
          </EventsTableBody>
        </EventsTable>
        {noMoreResults && <NoMoreEvents>{l10n('results.noMoreEvents')}</NoMoreEvents>}
        {isFetching && <LoadingEventsThrobber>{l10n('results.loadingEvents')}</LoadingEventsThrobber>}
      </EventsTableWrapper>
      {!!table.getPageCount() && (
        <TablePagination
          canPreviousPage={table.getCanPreviousPage()}
          canNextPage={table.getCanNextPage()}
          pageOptions={table.getPageOptions()}
          pageCount={table.getPageCount()}
          state={table.getState()}
          isLoading={isLoading}
          gotoPage={table.setPageIndex}
          nextPage={table.nextPage}
          previousPage={table.previousPage}
          setPageSize={table.setPageSize}
          showItemCount={total ? { totalItems: total } : undefined}
          pageLimit={PAGINATION_PAGE_LIMIT}
        />
      )}
    </>
  );
};

function LoadingRows({ headers }: { headers: Header<NoiseEvent, unknown>[] | undefined }) {
  if (!headers) return null;
  return (
    <>
      {Array(PAGE_SIZE)
        .fill(null)
        .map((_, index) => (
          <EventsTableRow data-testid="events-loading-row-loading" key={index}>
            {headers.map(header => (
              <EventsTableCell
                key={header.id}
                colSpan={header.colSpan}
                style={{ width: header.getSize() }}
                className={Classes.SKELETON}
              >
                &nbsp;
              </EventsTableCell>
            ))}
          </EventsTableRow>
        ))}
    </>
  );
}

const { accessor, display } = createColumnHelper<NoiseEvent>();

function useEventHistoryTableColumns() {
  const { l10n } = useI18n('app/views/alerts', 'eventsListing');
  const localTime = useLocalTimeCallback();
  const isRowExpanded = useIsRowExpanded();
  const handleExpansion = useHandleRowExpansion();
  const noiseMonitors = useNoiseMonitors();
  const hasAccess = useHasAccess();
  const isMobile = useIsMobile();

  return useMemo(() => {
    const action = {
      cell: display({
        id: ACTIONS_CELL_ID,
        cell: props => {
          const { id } = props.row.original;
          const isExpanded = isRowExpanded(id);
          return (
            <ExpandRowButton
              onClick={handleExpansion(id)}
              title={l10n('action.viewEvent', { id })}
              id={`expand-row-button-${id}`}
              aria-pressed={isExpanded}
            >
              <ExpandRowButtonIcon $isExpanded={isExpanded} />
            </ExpandRowButton>
          );
        },
      }),
      accessControl: [],
    };
    const event = {
      cell: accessor('id', {
        header: l10n('heading.event'),
        cell: ({ getValue }) => getValue(),
      }),
      accessControl: [],
    };
    const monitor = {
      cell: accessor('locationId', {
        header: l10n('heading.monitor'),
        cell: ({ getValue }) => {
          const locationId = getValue();
          return noiseMonitors.find(monitor => monitor.omnisLocationId === locationId)?.name ?? NO_DATA;
        },
        minSize: 100,
        maxSize: 150,
      }),
      accessControl: [],
    };
    const time = {
      cell: accessor('startTime', {
        header: l10n('heading.time'),
        cell: ({ getValue }) => {
          const startTime = getValue();
          const facilityDate = startTime ? localTime(startTime) : undefined;
          const date = facilityDate ? facilityDate.toLocaleString(DateTime.DATE_MED) : NO_DATA;
          const time = facilityDate ? facilityDate.toLocaleString(DateTime.TIME_24_SIMPLE) : NO_DATA;
          const startTimeMs = facilityDate ? facilityDate.toMillis() : 0;
          return (
            <span data-testid="events-listing-event-time" data-ms={startTimeMs}>
              {date} {time}
            </span>
          );
        },
        size: 180,
      }),
      accessControl: [],
    };
    const duration = {
      cell: accessor('endTime', {
        header: l10n('heading.duration'),
        cell: ({
          row: {
            original: { startTime, endTime },
          },
        }) => {
          const startTimeMs = startTime ? DateTime.fromISO(startTime).toMillis() : undefined;
          const endTimeMs = endTime ? DateTime.fromISO(endTime).toMillis() : undefined;
          if (!startTimeMs || !endTimeMs) return NO_DATA;
          const totalMs = endTimeMs - startTimeMs;
          const totalSeconds = Math.floor(totalMs / 1000);
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
      }),
      accessControl: [],
    };
    const sel = {
      cell: accessor('sel', {
        header: 'SEL',
        cell: formatEventData,
      }),
      accessControl: [],
    };
    const laeq = {
      cell: accessor('leq', {
        header: 'LAeq',
        cell: formatEventNoiseData,
      }),
      accessControl: [],
    };
    const lafMax = {
      cell: accessor('lfMax', {
        header: 'LAFmax',
        cell: formatEventNoiseData,
      }),
      accessControl: [],
    };
    const wind = {
      cell: accessor('windSpeed', {
        header: l10n('heading.windSpeed'),
        cell: formatEventData,
      }),
      accessControl: [],
    };
    const classifications = {
      cell: accessor('classifications', {
        header: l10n('heading.classification'),
        cell: ({ getValue }) => <EventClassificationIcons classifications={getValue()} />,
      }),
      accessControl: [KnownAccessControls.app.noise.classification.data.read._],
    };
    const disregarded = {
      cell: accessor('disregarded', {
        header: l10n('heading.disregarded'),
        cell: ({ getValue }) => <div>{getValue() ? 'Disregarded' : ''}</div>,
      }),
      accessControl: [KnownAccessControls.app.noise.classification.data.read._],
    };
    const comments = {
      cell: accessor('comments', {
        header: l10n('heading.comments'),
        cell: ({ getValue }) => {
          const comments = getValue();
          return comments && <EventClassificationComments comments={comments} />;
        },
      }),
      accessControl: [KnownAccessControls.app.noise.classification.data.read._],
    };
    const mobileCells = [time, monitor];
    const desktopCells = [
      action,
      event,
      monitor,
      time,
      duration,
      sel,
      laeq,
      lafMax,
      wind,
      classifications,
      disregarded,
      comments,
    ];
    return (isMobile ? mobileCells : desktopCells).flatMap(({ cell, accessControl }) => {
      if (hasAccess(accessControl)) return [cell];
      return [];
    });
  }, [handleExpansion, hasAccess, isMobile, isRowExpanded, l10n, localTime, noiseMonitors]);
}

function useHandleRowExpansion() {
  const dispatch = useAppDispatch();
  const { selectedAlertEvent } = useAppSelector(state => state.ui);
  return useCallback(
    (id: number) => () => {
      if (selectedAlertEvent === id) return dispatch(deselectAlertEvent());
      return dispatch(selectAlertEvent(id));
    },
    [dispatch, selectedAlertEvent]
  );
}

function useIsRowExpanded() {
  const { selectedAlertEvent } = useAppSelector(state => state.ui);
  return useCallback(
    (eventId: number) => {
      return selectedAlertEvent === eventId;
    },
    [selectedAlertEvent]
  );
}

function formatEventData<TValue>({ getValue }: CellContext<NoiseEvent, TValue>): React.ReactNode {
  const value = getValue();
  if (typeof value !== 'number') return NO_DATA;
  // Wind seems to return as -1 if there is no data
  if (value === -1) return NO_DATA;
  return value.toFixed(1);
}

function formatEventNoiseData<TValue>({
  getValue,
  row: { original },
}: CellContext<NoiseEvent, TValue>): React.ReactNode {
  const value = getValue();
  if (original.weighting !== 'A') return NO_DATA;
  if (typeof value !== 'number') return NO_DATA;
  return value.toFixed(1);
}

/**
 * Set search parameters when the react-table pagination state changes
 */
function useHandlePaginationChange() {
  const {
    searchParams: {
      page: [page],
    },
    setSearchParams,
    clearSearchParams,
  } = useSearchParams([SEARCH_PARAM_PAGE]);
  return useCallback(
    (state: Updater<PaginationState>) => {
      if (typeof state !== 'function') return;
      const pageIndex = page ? Number(page) - 1 : 0;
      const { pageIndex: newPageIndex } = state({ pageIndex, pageSize: PAGE_SIZE });
      // react-table page index is zero-based
      if (newPageIndex === 0) {
        return clearSearchParams(['page']);
      }
      setSearchParams({ page: [(newPageIndex + 1).toString()] });
    },
    [clearSearchParams, page, setSearchParams]
  );
}
