import { gql } from '@apollo/client';

export const FETCH_MEASUREMENT_GROUPS_BY_PROCESS_VARIABLE_ID = gql`
  query FetchMeasurementGroupByProcessVariableId($variableFilter: VariableFilter) {
    facility {
      id
      processVariables(filter: $variableFilter) {
        id
        name
        measurementGroups {
          id
          description
        }
      }
    }
  }
`;
